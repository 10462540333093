<template>
  <section class="text-left message ms-Fabric dialogWindow" :key="viewIndex" v-bind:class="windowClass">
    <b-overlay :show="loading" rounded="sm">
      <div v-if="opened" style="position: relative;">

          <b-button @click="refreshBtn" class="no-border"
            ><i class="fal fa-sync"></i
          > <span class="hidden-mobile">&nbsp;{{ $t("UPDATE") }}</span></b-button>

           <span v-if="message">
            <b-button :href="direktLink" class="no-border" target="_blank">
              <i class="fal fa-external-link"></i
              ><span class="hidden-mobile">&nbsp;{{ $t("OPEN_NEW_TAB") }}</span>
            </b-button>
            <b-button
              class="no-border"
              @click="removeMessage"
              v-if="user.information.organisation.users_can_remove == 1"
            >
              <i class="fal fa-trash-alt"></i
              ><span class="hidden-mobile">&nbsp;{{ $t("DELETE") }}</span>
            </b-button>
          </span>

        <b-card class="card-message">
          <template v-slot:header>
            <h4 class="m-0 p-0">{{ message.subject }}</h4>
          </template>

          <UserInfo
            class="mt-4"
            image="/img/default-avatar.png"
            :primary="createdName"
            :secondary="createdTime"
          ></UserInfo>

          <hr class="pb-0 mb-0 mt-2" />

          <div class="mt-2">
            <span
              v-for="(item, index) in participantsNoCreator"
              :key="index + '-participant'"
            >
              <Participant
                :item="item"
                :status="participant_status"
                :creator="isCreator"
                :islast="checkLastParticipant(index)"
              ></Participant>
            </span>

            <span
              v-for="(item, index) in external"
              :key="index + '-external'"
              class="ms-fontSize-12"
            >
              <SecureLink
                :item="item"
                :creator="isCreator"
                :islast="external.length - 1 == index"
              ></SecureLink>
            </span>
          </div>

          <div class="mt-2">
            <span
              v-for="(item, index) in message.attachments"
              :key="index + '-attachment'"
              class="ms-fontSize-12"
            >
              <Attachment
                :item="item"
                :index="index"
                :message_uuid="message_uuid"
                :addinClient="addinClient"
                @openBrowserWindow="openBrowserWindow"
              ></Attachment>
            </span>
          </div>

          <BodyText :body="message.body"></BodyText>

          <template v-slot:footer>
            <div class="ml-2">
              <a @click="show = true" class="clickable"><i class="ml-2 fal fa-info-circle"></i> {{$t("DETAILS")}}</a>
            </div>
          </template>
        </b-card>

        <b-sidebar
          id="message-participant"
          v-model="show"
          right
          no-slide
          shadow
          backdrop
        >
          <div class="px-3 py-2">
            {{ $t("MESSAGE_DETAILS") }}

            <hr class="pb-0 mb-0 mt-2 mb-3" />

            <h6 class="mb-0 no-uppercase">{{ $t("MESSAGE_UUID") }}</h6>
            {{ message.message_uuid }}

            <div v-if="message.creator.name != ''">
              <h6 class="mt-3 mb-0 no-uppercase">{{ $t("CREATOR_UUID") }}</h6>
              {{ message.creator.user_uuid }}
            </div>

            <h6 class="mt-3 mb-0 no-uppercase">{{ $t("MESSAGE_VERSION") }}</h6>
            {{ message.version }}

            <div v-if="!responseType">
              <h6 class="mt-3 mb-0 no-uppercase">
                {{ $t("LOA.TITLE_PARTICIPANT") }}
              </h6>

              <i
                class="fal fa-info-square"
                v-b-tooltip.hover
                :title="$t('LOA.INFO.' + message.settings.loa_level)"
              ></i>
              LOA{{ message.settings.loa_level }}
            </div>

            <h6 class="mt-3 mb-0 no-uppercase">{{ $t("TYPE") }}</h6>
            {{ $t("MESSAGES.TYPE." + message.message_type) }}

            <h6 class="mt-3 mb-0 no-uppercase" v-if="!responseType">
              {{ $t("RESPONSE") }}
            </h6>
            <span v-if="!responseType">{{
              $t("MESSAGES.REQUESTED_RESPONSE")
            }}</span>

            <hr class="pb-0 mb-0 mt-2" />
          </div>
        </b-sidebar>
        <RespondedMessage
          class="mt-2"
          v-if="responded_message_id != '' && !showResponseForm"
          :message_uuid="responded_message_id"
        ></RespondedMessage>

        <ResponseToMessage
          class="mt-2"
          @responded="setResponse"
          v-if="showResponseForm"
          :message_uuid="message_uuid"
        ></ResponseToMessage>
     
      </div>
      <div
        v-if="!opened && !loading"
      >

          <InformationMessage type="error" v-if="errorMessage == ''">
            <i class="fal fa-exclamation-circle"></i>
            {{ $t("READ.CANNOT_OPEN") }}
          </InformationMessage>
          <InformationMessage type="error" v-if="errorMessage != ''">
            <i class="fal fa-exclamation-circle"></i>
            {{ errorMessage }}
          </InformationMessage>
          <LoaLevel :loa_level="loa_error"></LoaLevel>
      </div>
    </b-overlay>
  </section>
</template>
<script>
export default {
  components: {
    LoaLevel: () => import("@/components/Read/LoaLevel"),
    Participant: () => import("@/components/Read/Participant"),
    SecureLink: () => import("@/components/Read/SecureLink"),
    BodyText: () => import("@/components/Read/BodyText"),
    Attachment: () => import("@/components/Read/Attachment"),
    InformationMessage: () => import("@/components/Layout/InformationMessage"),
    UserInfo: () => import("@/components/Layout/UserInfo"),
    ResponseToMessage: () => import("@/components/Read/ResponseToMessage"),
    RespondedMessage: () => import("@/components/Read/RespondedMessage"),
  },
  props: ["message_uuid"],
  data() {
    return {
      viewingIndex: 0,
      loa_error: 0,
      show: false,
      addinClient: "",
      opened: false,
      loading: true,
      participant_status: [],
      external_status: [],
      errorMessage: "",
      message: {},
      external: [],
      responded_message_id: "",
      isSecured: false,
      mainProps: { width: 20, height: 20 },
    };
  },
  methods: {
    async init() {
      this.$Office.context.ui.messageParent(
        JSON.stringify({
          message: "OPENED",
        })
      );
      await this.readMessage();
    },
    openBrowserWindow(url){
      this.$Office.context.ui.messageParent(
        JSON.stringify({
          message: "OPENBROWSERWINDOW",
          url: url
        })
      );
    },
    checkLastParticipant(index) {
      if (this.external.length != 0) {
        return false;
      } else {
        return (this.participantsNoCreator.length - 1 == index);
      }
    },
    async refreshBtn(){
      this.viewingIndex = this.viewingIndex + 1;
      await this.readMessage();
    },
    setResponse(message_uuid) {
      this.responded_message_id = message_uuid;
    },
    removeMessage() {
      let self = this;
      const h = this.$createElement;
      let messageVNode = h('p', { class: ['mb-0 pb-0'] }, [
          h('p', { class: ['text-center mb-0 pb-0'] }, this.$t('CONFIRM.DELETE_MESSAGE'))
        ]);
      if(this.isCreator)
      {
        messageVNode = h('p', { class: ['mb-0 pb-0'] }, [
          h('p', { class: ['text-center'] }, this.$t('CONFIRM.DELETE_MESSAGE')),
          h('p', { class: ['text-center mb-0 pb-0'] }, this.$t('CONFIRM.DELETE_MESSAGE_CREATOR'))
        ]);
      }
      if(!this.regular_message)
      {
        messageVNode = h("p", { class: ["mb-0 pb-0"] }, [
          h(
            "p",
            { class: ["text-center mb-0 pb-0"] },
            this.$t("CONFIRM.DELETE_MEETING")
          ),
        ]);
        if (this.isCreator) {
          messageVNode = h("p", { class: ["mb-0 pb-0"] }, [
            h("p", { class: ["text-center"] }, this.$t("CONFIRM.DELETE_MEETING")),
            h(
              "p",
              { class: ["text-center mb-0 pb-0"] },
              this.$t("CONFIRM.DELETE_MEETING_CREATOR")
            ),
          ]);
        }      
      }
      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          size: "md",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: this.$t("YES"),
          cancelTitle: this.$t("NO"),
          bodyClass: "messagebox",
          footerClass: "p-2  text-center messagebox",
          hideHeaderClose: false,
          centered: true,
        })
        .then(function(value) {
          if (value) {
            self.$http
              .post(self.user.hostname + "/message/remove", {
                message_uuid: self.message_uuid,
                functionbox_uuid: ""
              })
              .then(() => {
                self.closeDialog();
              })
              .catch(function(error) {
                if(error.toString() == "Error: fail_4_4") return;
                self.$noty.error(self.$t("ERROR.COULD_NOT_SAVE"));
              });
          }
        })
        .catch(function() {});
    },
    async readMessage() {
      this.errorMessage = "";
      this.loa_error = 0;
      try {
        let result = await this.$http.post(
          this.user.hostname + "/message/uuid/open",
          {
            message_uuid: this.message_uuid,
          }
        );
        this.message = result.data.message;
        this.participants = result.data.participants;
        this.external = result.data.external;
        this.opened = true;
        this.loading = false;
        return true;
      } catch (error) {
        this.loading = false;
        this.opened = false;
        if(error.toString() == "Error: fail_4_4") return false;
        if (error.response.data != undefined) {
          if (
            error.response.data == "ERROR.LOA_LOW3" ||
            error.response.data == "ERROR.LOA_LOW2"
          ) {
            this.loa_error = parseInt(error.response.data.slice(-1));
          }
          this.errorMessage = this.$t(error.response.data);
        }
        return false;
      }
    },
    replyMessage() {
      this.$Office.context.ui.messageParent(
        JSON.stringify({
          message: "REPLY",
          content: this.cleanMessage,
        })
      );
    },
    replyAllMessage() {
      this.$Office.context.ui.messageParent(
        JSON.stringify({
          message: "REPLYALL",
          content: this.cleanMessage,
        })
      );
    },
    closeDialog() {
      this.$Office.context.ui.messageParent(
        JSON.stringify({
          message: "CLOSE",
        })
      );
    },
  },
  computed: {
    direktLink() {
      return this.user.hostname.replace("/server/rest","") + "/message/" + this.message.message_uuid;
    },
    regular_message(){
      if(this.message.message_type == 'message' || this.message.message_type == "response" ||
        this.message.message_type == "link-response")
        {
          return true;
        }else{
          return false;
        }
    },
    responseType() {
      return (
        this.message.message_type == "response" ||
        this.message.message_type == "link-response"
      );
    },
    createdTime() {
      return this.$luxonDateTime.fromISO(this.message.created_date).toLocaleString(this.$luxonDateTime.DATETIME_MED);
    },
    createdName() {
      if (this.message.creator.name == "") {
        return this.message.creator.email;
      } else {
        return (
          this.message.creator.name +
          ", " +
          this.message.creator.organisation_name
        );
      }
    },
    isCreator() {
      return this.user.information.user_uuid == this.message.creator.user_uuid;
    },
    participantsNoCreator() {
      return this.participants.filter(
        (c) => c.email != this.user.information.email
      );
    },
    myResponse() {
      if (this.responded_message_id != "") {
        return this.responded_message_id;
      }
      let me = this.participants.filter(
        (c) => c.user_uuid == this.user.information.user_uuid
      );
      if (me.length != 0) {
        return me[0].response_message_uuid;
      }
      return "";
    },
    showResponseForm() {
      if (!this.isCreator && this.message.settings.require_response) {
        if (this.myResponse == "") {
          return true;
        }
      }
      return false;
    },
    showResponse() {
      if (!this.isCreator && this.message.settings.require_response) {
        if (this.myResponse != "") {
          return true;
        }
      }
      return false;
    },
    windowClass(){
      if(this.$Office.context.diagnostics.platform == undefined)
      {
        return "";
      }else{
        if(this.$Office.context.diagnostics.platform == "OfficeOnline")
        {
          return "pr-4";
        }else{
           return "pl-md-4 pr-md-4"; 
        }
      }
    },
    viewIndex() {
      return "view_" + this.viewingIndex;
    }
  },
  watch: {
    message_uuid: function(val, oldval) {
      if (val != oldval) {
        this.getInformation();
      }
    },
  },
  mounted() {
    console.log("ReadDialog mounted");
    this.init();
  },
};
</script>
<style></style>